// @ts-nocheck
import { EditorReadyOptions, EditorType } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';

let editorType: any;
const cache = {};
let instance: any;
let refresh: any;

export function editorReady(
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: EditorReadyOptions,
  flowAPI: EditorScriptFlowAPI,
) {
  editorType = options?.origin?.type;
  instance = options?.initialAppData?.instance;
  refresh = editorSDK.document.application.livePreview.refresh.bind(
    editorSDK.document.application.livePreview,
  );
  editorSDK.addEventListener('widgetAdded', hadnleGalleryDuplicationEvent);

  // if (options.firstInstall) {
  //   editorSDK.document.tpa.add.component('token', {
  //     managingAppDefId: appDefId,
  //     componentType: editorSDK.document.tpa.TPAComponentType.Widget,
  //     appDefinitionId: appDefId,
  //     widget: {
  //       widgetId: 'pro-gallery',
  //     },
  //   });
  // }
}

const hadnleGalleryDuplicationEvent = (args: any) => {
  if (args?.detail.componentRef.type === 'DESKTOP') {
    const originCompId = args?.detail?.originalComponentId;
    const compId = args?.detail?.componentRef?.id;
    if (!compId || compId.includes('TPAMultiSection')) {
      return;
    }
    return createGallery({ compId, originCompId });
  }
};

const createGallery = async ({
  compId,
  originCompId,
  originInstanceId,
}: any) => {
  if (!compId || cache[compId]) {
    return;
  } else {
    cache[compId] = true;
  }
  const editorName = convertEditorType(editorType);
  try {
    if (editorName === 'EDITORX' || editorName === 'EDITOR') {
      const requestUrl =
        'https://editor.wix.com/_api/pro-gallery-editor-webapp/v1/app/galleries'; // This was tested with editor. not editorx. might have CORS when we start testing it to

      const response = await fetch(requestUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: instance,
        },
        body: JSON.stringify({
          compId,
          originCompId,
          originInstanceId,
          editorType: editorName,
        }),
      });
      const data = await response.json();
      if (typeof data?.galleryId === 'string') {
        refresh('', {
          shouldFetchData: true,
          source: 'AFTER_GALLERY_CREATED',
        });
        return Promise.resolve();
      } else {
        throw new Error('no galleryId returned from the server');
      }
    }
  } catch (e) {
    console.error(
      'Could not create a new gallery in the server using the provided data',
      JSON.stringify({
        compId,
        originCompId,
        originInstanceId,
        editorType: editorName,
      }),
      e,
    );
  }
};

const convertEditorType = (type: any) => {
  switch (type) {
    case EditorType.Classic:
      return 'EDITOR';
    case EditorType.Responsive:
      return 'EDITORX';
    case EditorType.ADI:
      return 'ADI';
    default:
      // there are two more types in the possible EditorTypes, our server doesnt know about them
      return type;
  }
};
